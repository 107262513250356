$separator-color-light: #f3f3f3;
$separator-color: #d7d7d7;
$background-color: #f8f8f8;
$foreground-color : white;
$input-background: white;

$dark-btn-background: #575057;
$light-btn-background: #d4d4d4;

$button-text-color: #fff;

$theme-color-1: #b07d41;
$theme-color-2: #4556ac;
$theme-color-3: #af67a4;
$theme-color-4: #743c6e;
$theme-color-5: #4b5480;
$theme-color-6: #795d75;

$primary-color: #303030;
$secondary-color: #8f8f8f;
$muted-color: #909090;

$gradient-color-1 : #b07d41;
$gradient-color-2 : #3c164f;
$gradient-color-3 : #8a3481;

$shadowOffsetsTop : 1 3 10 14 19;
$shadowBlursTop: 1.5 5 10 14 19;
$shadowOpacitiesTop: 0.04 0.1 0.19 0.25 0.3;

$shadowOffsetsBottom : 1 3 6 10 15;
$shadowBlursBottom: 3 6 6 5 6;
$shadowOpacitiesBottom: 0.04 0.1 0.2 0.22 0.22;

$logoPath: "/assets/img/logo-black.svg";
$logoPath2: "/assets/img/logo-black2.svg";
$logoPathMobile: "/assets/img/logo-mobile.svg";

@import "../_mixins.scss";
@import "../_gogo.style.scss";
@import "./_variables.scss";
@import "./_sidebar.scss";
@import "./_header.scss";
@import "./_shop.scss";
@import "./_product.scss";
@import "./_cart.scss";
@import "./_checkout.scss";
@import "./_image-slider.scss";
@import "./_slider.scss";
@import "./_banner.scss";